import { defineStore } from "pinia";
import { router } from "@/router";
import { DashboardRoutes } from "@/router/routes";
import { getConfigRealInfo } from "@/api/system";
import { getHistoryCount } from "@/api/taskModule/taskModule";
import {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordName,
} from "vue-router";
import { reactive, toRefs } from "vue";
import dayjs from "dayjs";

export interface Menu {
  key: string;
  label: string;
  icon: string;
  routeName?: string;
  children?: Menu[];
}

type TabMapValue = {
  name: RouteLocationNormalized["name"];
  meta?: RouteLocationNormalized["meta"];
};

type TabMap = Map<RouteRecordName, TabMapValue>;

interface GLobalState {
  menus: Menu[];
  routeMap: Map<string, [string, string, number]>;
  pinTabMap: TabMap;
  tabMap: TabMap;
  activedRoute?: RouteLocationNormalizedLoaded;
  collapseFlag: boolean;
  scene: string;
  coverFlag: boolean;
}

const getInitState = (): GLobalState => ({
  menus: [] as Menu[],
  routeMap: new Map([["Dashboard", ["首页", "Home2Icon", 0]]]),
  pinTabMap: new Map(
    DashboardRoutes.filter((x) => x.meta?.isPin).map((x) => {
      return [
        x.name,
        {
          name: x.name,
          meta: x.meta,
        },
      ];
    })
  ),
  tabMap: new Map(),
  activedRoute: undefined,
  collapseFlag: false,
  scene: "",
  coverFlag: false,
});

export const useGlobalStore = defineStore("global", () => {
  const sessionCollapseFlag = sessionStorage.getItem("collapseFlag") || "1";

  const state = reactive<GLobalState>(
    Object.assign(getInitState(), {
      collapseFlag: sessionCollapseFlag === "1" ? true : false,
    })
  );

  const FETCH_MENUS = async () => {
    let taskCount = 0;

    try {
      taskCount = await getHistoryCount({
        startedDate: dayjs().subtract(2, "month").format("YYYY-MM-DD"),
      });
    } catch (error) {
      console.error(error);
    }

    const data = [
      {
        name: "2062",
        hideMenu: false,
        pathName: "UpComingTasks",
        meta: {
          title: "待办任务",
          icon: "ReportIcon",
          noCache: true,
          hideMenu: false,
        },
      },
      {
        name: "2063",
        hideMenu: true,
        pathName: "TaskDetail",
        meta: {
          title: "任务详情",
          icon: "DirectionSignIcon",
          noCache: true,
          hideMenu: true,
        },
      },
      {
        name: "2064",
        hideMenu: false,
        pathName: "CompleteTask",
        meta: {
          title: "已办任务",
          icon: "ClipboardCheckIcon",
          noCache: true,
          hideMenu: false,
        },
      },
      {
        name: "2065",
        hideMenu: false,
        pathName: "MineTask",
        meta: {
          title: "我的发起",
          icon: "UserIcon",
          noCache: true,
          hideMenu: false,
          badge: taskCount,
        },
      },
      {
        name: "2066",
        hideMenu: false,
        pathName: "CC",
        meta: {
          title: "我的抄送",
          icon: "MailOpenedIcon",
          noCache: true,
          hideMenu: false,
        },
      },
      {
        name: "2067",
        hideMenu: true,
        pathName: "MsgReminder",
        meta: {
          title: "消息提醒",
          icon: "DirectionSignIcon",
          noCache: true,
          hideMenu: false,
        },
      },
      {
        name: "2068",
        hideMenu: false,
        pathName: "SealManage",
        meta: {
          title: "用印管理",
          icon: "WritingSignIcon",
          noCache: true,
          hideMenu: false,
        },
      },
      // {
      //   name: "2069",
      //   hideMenu: false,
      //   pathName: "MyRelated",
      //   meta: {
      //     title: "与我相关",
      //     icon: "CirclesRelationIcon",
      //     noCache: true,
      //     hideMenu: false,
      //   },
      // },
    ];
    const routeMap = new Map(state.routeMap);

    const recursor = (e) => {
      e.forEach((element) => {
        const { pathName, meta } = element;
        if (pathName && meta) {
          const { title, icon, badge } = meta;
          routeMap.set(pathName, [title, icon, badge ?? 0]);
        } else {
          routeMap.set(pathName, ["", "", 0]);
        }
      });

      return e
        .filter((x) => !x.hideMenu)
        .map((x) => {
          return {
            key: x.name,
            label: x.meta?.title,
            icon: x.meta?.icon,
            routeName: x.pathName,
            children: x.children ? recursor(x.children) : undefined,
            path: x.path,
          };
        });
    };

    state.menus = recursor(data);
    state.routeMap = routeMap;
  };
  function ACTIVE_ROUTE(route: RouteLocationNormalizedLoaded) {
    state.activedRoute = route;
  }
  function CLEAN_TAB() {
    const map = new Map();
    state.tabMap = map;
  }
  function CLEAN_TAB_WITHOUT(route: TabMapValue) {
    if (route.name) {
      const map = new Map([[route.name, route]]);
      state.tabMap = map;
    }
  }
  function CLOSE_TAB(tabKey: string) {
    state.REMOVE_TAB(tabKey);
    const isActive = state.activedRoute?.name === tabKey;
    if (isActive) {
      const tabSize = state.tabMap.size;
      if (tabSize >= 1) {
        const lastRoute = [...state.tabMap.values()][tabSize - 1];
        if (lastRoute.name) {
          router.replace({
            name: lastRoute.name,
          });
        }
      } else {
        const firstPinRoute = [...state.pinTabMap.values()][0];
        if (firstPinRoute.name) {
          router.replace({
            name: firstPinRoute.name,
          });
        }
      }
    }
  }
  function TOGGLE_COLLAPSE() {
    state.collapseFlag = !state.collapseFlag;
    sessionStorage.setItem("collapseFlag", state.collapseFlag ? "1" : "0");
  }
  function SET_SCENE(scene: string) {
    state.scene = scene;
  }
  async function FETCH_COVER_FLAG() {
    const flag = await getConfigRealInfo();

    if (flag && flag === "y" && import.meta.env.MODE?.includes("mask")) {
      state.coverFlag = true;
    } else {
      state.coverFlag = false;
    }
  }
  async function FETCH_UNFINISHED_TASK() {
    const taskCount = await getHistoryCount(
      dayjs().subtract(2, "month").format("YYYY-MM-DD")
    );
    return taskCount;
  }

  return {
    ...toRefs(state),
    FETCH_MENUS,
    ACTIVE_ROUTE,
    CLEAN_TAB,
    CLEAN_TAB_WITHOUT,
    CLOSE_TAB,
    TOGGLE_COLLAPSE,
    SET_SCENE,
    FETCH_COVER_FLAG,
    FETCH_UNFINISHED_TASK,
  };
});
