import { createRouter, createWebHistory } from "vue-router";
import { Routes } from "./routes";
import { useUserStore } from "@/store/userStore";
import { config } from "@/config";

const router = createRouter({
  history: createWebHistory(config.BASE),
  routes: Routes,
});

// router.afterEach((to) => {
//   if (to.path.match(/^\/dashboard/) && !to.meta.isPin) {
//     const globalStore = useGlobalStore();
//     globalStore.ADD_TAB(to);
//   }
// });

router.beforeEach((to) => {
  const userStore = useUserStore();
  const token = userStore.token;

  if (to.matched[0]?.name === "_Dashboard" && !token) {
    return {
      name: "Home",
      replace: true,
      query: {
        redirect: to.fullPath,
      },
    };
  }
});

export { router };
