import { http } from "@/utils/http";

export const login = async (data: any) => {
  const res = await http.post("/login", data, {
    headers: {
      Authorization: null,
    },
  });
  return res.data?.data;
};

// 自动登陆
export const getH5FSLogin = async (data: any) => {
  const res = await http.post("/login/fsh5", data, {
    headers: {
      Authorization: null,
    },
  });
  return res.data?.data;
};

export const postToolFsJsApiFetch = async (data: any) => {
  const res = await http.get(`/tool/fs/jsapi/fetch`, {
    params: data,
  });
  return res.data?.data;
};

export const getInfo = async () => {
  const res = await http.get("/getInfo");
  return res.data?.data;
};

export const getPermCode = async () => {
  const res = await http.get("/auth/getPermCode");
  return res.data?.data;
};

export const getRouters = async () => {
  const res = await http.get("/getRouters");
  return res.data?.data;
};

// 查询授权列表
export const getEmpower = async (data: any) => {
  const res = await http.get("/pc/process/empower/page", {
    params: data,
  });
  return res.data?.data;
};

// 新增授权
export const postEmpowerAdd = async (data: any) => {
  const res = await http.post("/pc/process/empower/add", data);
  return res.data?.data;
};

// 中止授权
export const postEmpowerStop = async (data: any) => {
  const res = await http.post("/pc/process/empower/stop", data);
  return res.data?.data;
};

// 查询用户可以审批的流程
export const getEmpowerUserprocess = async (data: any) => {
  const res = await http.get("/pc/process/empower/userprocess", {
    params: data,
  });
  return res.data?.data;
};

// 通过Id查询流程
export const getEmpowerInfo = async (data: any) => {
  const res = await http.get("/pc/process/empower/info", {
    params: data,
  });
  return res.data?.data;
};

// 切换自己待办任务页面的样式
export const postUserSettings = async (data: any) => {
  const res = await http.post("/pc/process/user/settings", data);
  return res.data?.data;
};
