const config = {
  BASE: import.meta.env.VITE_BASE ?? "/",
  PRODUCTION: import.meta.env.PROD,
  TITLE: import.meta.env.VITE_TITLE ?? "",
  API: import.meta.env.VITE_API ?? "",
  OSS_BASE: import.meta.env.VITE_OSS_BASE ?? "",
  PDF_API: import.meta.env.VITE_PDF_API ?? "",
};

export { config };
