import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { config } from "@/config";
import { Notification } from "@arco-design/web-vue";
import { router } from "@/router";
import { useUserStore } from "@/store/userStore";

let httpqueue: AxiosRequestConfig[] = [];

export class ResponseError extends Error {
  response: AxiosResponse;

  constructor(message: string, response: AxiosResponse) {
    super(message);
    this.response = response;
  }
}

const http = axios.create({
  baseURL: config.API,
  withCredentials: false,
});

http.interceptors.request.use(
  function (config) {
    const userStore = useUserStore();
    const headers = config.headers;
    const token = userStore.token;
    if (headers && !("Authorization" in headers) && token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    httpqueue.push(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async function (response) {
    const config = response.config;
    const index = httpqueue.indexOf(config);

    if (index > -1) {
      httpqueue.splice(index, 1);
    } else {
      return Promise.reject(new Error("Expired"));
    }

    if (response.headers["content-type"]?.includes("application/json")) {
      const code = response.data.code;
      const error = new ResponseError(
        `${response.data.code}:${response.data.msg}`,
        response
      );

      if (code === 401) {
        const userStore = useUserStore();

        httpqueue = [];
        userStore.SIGNOUT();
        router.replace({
          name: "Home",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });

        return Promise.reject(error);
      }

      if (code !== 200 && code !== 0 && code !== 4015) {
        //4015是任务不存在时
        Notification.error({
          title: "错误",
          content: response.data?.msg || "请求失败",
        });

        return Promise.reject(error);
      }
    }

    return response;
  },
  function (error) {
    const config = error.config;
    const index = httpqueue.indexOf(config);

    if (index > -1) {
      httpqueue.splice(index, 1);
    } else {
      return Promise.reject(new Error("Expired"));
    }
    // 授权无效
    if (error.response.status === 401) {
      const userStore = useUserStore();

      httpqueue = [];
      userStore.SIGNOUT();
      router.replace({
        name: "Home",
      });
    } else if (error.message !== "canceled") {
      Notification.error({
        title: "错误",
        content: error.message || "网络错误",
      });
    }

    return Promise.reject(error);
  }
);

export { http };
