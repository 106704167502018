import { http } from "@/utils/http";
import { Message } from "@arco-design/web-vue";

//待办任务列表
export const getUpComingTaskList = async (params: any) => {
  const res = await http.get(`/pc/process/myTask`, {
    params,
    headers: {
      tenant: "sap",
    },
  });
  return res.data?.data;
};
//抄送列表
export const getCcTaskList = async (params: any) => {
  const res = await http.get(`/pc/process/myCc`, {
    params,
    headers: {
      tenant: "sap",
    },
  });
  return res.data?.data;
};
// 抄送用——获取流程详情
export const getTaskInfo = async (params: any) => {
  const res = await http.get(`/pc/process/info`, {
    params,
  });
  return res.data;
};

//流程分类
export const getFlowCategoryList = async () => {
  const res = await http.get(`/pc/process/category`);
  return res.data?.data;
};
//任务详情
export const getTaskDetail = async (params: any) => {
  const res = await http.get(`/pc/process/task/info`, { params });
  return res.data;
};

//执行任务
export const postTaskOpe = async (data: any) => {
  const res = await http.post(`/pc/process/task/complete`, data);
  return res.data?.data;
};
//授权任务
export const postTaskEntrust = async (data: any) => {
  const res = await http.post(`/pc/process/task/delegate`, data);
  return res.data?.data;
};

//授权任务
export const postTaskCallback = async (data: any) => {
  const res = await http.post(`/pc/process/send/callback`, data);
  return res.data?.data;
};

//搜素用户
export const searchUser = async (params: any) => {
  const res = await http.get(`/system/user/getCcUser`, { params });
  return res.data?.data;
};

//流程分类
export const getOrginfo = async () => {
  const res = await http.get(`/pc/process/category`);
  return res.data?.data;
};
//获取通知信息
export const getNotification = async (params: any) => {
  const res = await http.get(`/pc/process/notification`, {
    params,
  });
  return res.data?.data;
};
//获取流程信息
export const getBusinessinfoMessage = async (params: any) => {
  const res = await http.get(`/pc/process/businessinfo`, {
    params,
  });
  if (!res.data?.data) {
    Message.warning("此合同尚未提交双签！");
  }
  return res.data?.data;
};
//获取流程信息-无提示
export const getBusinessinfo = async (params: any) => {
  const res = await http.get(`/pc/process/businessinfo`, {
    params,
  });
  return res.data?.data;
};
//根据业务编号查询业务历史流程
export const getBusinessHistory = async (params: any) => {
  const res = await http.get(`/pc/process/business/history`, {
    params,
  });
  return res.data?.data;
};

//转发流程信息
export const postTransmit = async (data: any) => {
  const res = await http.post(`/pc/process/transmit`, data);
  return res.data?.data;
};

//查询某时间前未关闭的流程数量
export const getHistoryCount = async (params: any) => {
  const res = await http.get(`/pc/process/history/count`, {
    params,
  });
  return res.data?.data;
};

//查询某时间前未关闭的流程分页
export const getHistoryPage = async (params: any) => {
  const res = await http.get(`/pc/process/history/page`, {
    params,
  });
  return res.data?.data;
};

//关闭流程
export const getHistoryClose = async (data: any) => {
  const res = await http.post(`/pc/process/history/close`, data);
  return res.data?.data;
};

//客商新增/编辑
export const getOrginfoSave = async (data: any) => {
  const res = await http.post(`/yx/orginfo/save`, data);
  return res.data?.data;
};

//客商导出
export const getOrginfoExport = async (data: any) => {
  const res = await http.post(`/yx/orginfo/export`, data, {
    responseType: "blob",
  });
  return res.data;
};

//客商新增/编辑
export const postOrginfoDisable = async (data: any) => {
  const res = await http.post(`/yx/orginfo/disable`, data);
  return res.data?.data;
};

//联系人列表
export const getContactsPage = async (params: any) => {
  const res = await http.get(`/yx/contacts/page`, { params });
  return res.data?.data;
};

//联系人详情
export const getContacts = async (params: any) => {
  const res = await http.get(`/yx/contacts/${params}`);
  return res.data?.data;
};

//联系人删除
export const deleteContacts = async (params: any) => {
  const res = await http.delete(`/yx/contacts/${params}`);
  return res.data?.data;
};

//联系人导出
export const getContactsExport = async (data: any) => {
  const res = await http.post(`/yx/contacts/export`, data, {
    responseType: "blob",
  });
  return res.data;
};

//联系人保存
export const getContactsSave = async (data: any) => {
  const res = await http.post(`/yx/contacts/save`, data);
  return res.data?.data;
};

//实物确认开关
export const getConfigType = async () => {
  const res = await http.get(`/system/config/6`);
  return res.data?.data;
};

//实物确认开关修改
export const putConfigType = async (data: any) => {
  const res = await http.put(`/system/config`, data);
  return res.data?.data;
};

//与我相关列表
export const getRelatedTaskList = async (params: any) => {
  const res = await http.get(`/pc/process/usertaskhistory/page`, {
    params,
    headers: {
      tenant: "sap",
    },
  });
  return res.data?.data;
};
