import MainerWrap from "@/layouts/dashboard/MainerWrap";
import {
  AsyncComponentLoader,
  Component,
  defineAsyncComponent,
  defineComponent,
  h,
} from "vue";
import { FullLoader } from "@/components/FullLoader";
import { RouteMeta } from "vue-router";

interface Route {
  path: string;
  name: string;
  meta?: RouteMeta;
  component: AsyncComponentLoader | Component;
  children?: Route[];
}

const AsyncPageWrap = (component: AsyncComponentLoader) => {
  const AsyncPage = defineAsyncComponent({
    loader: component,
    loadingComponent: FullLoader,
  });

  return defineComponent({
    render() {
      return h(AsyncPage);
    },
  });
};

// 任务模块
const TaskModule: Route[] = [
  {
    path: "taskModule/upComingTasks",
    name: "UpComingTasks",
    meta: {
      title: "待办任务",
    },
    component: () => import("@/views/taskModule/upComingTasks"),
  },
  {
    path: "taskModule/taskDetail",
    name: "TaskDetail",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/taskModule/taskDetail"),
  },
  {
    path: "taskModule/completeTask",
    name: "CompleteTask",
    meta: {
      title: "已办任务",
    },
    component: () => import("@/views/taskModule/completeTask"),
  },
  {
    path: "taskModule/mineTask",
    name: "MineTask",
    meta: {
      title: "我的发起",
    },
    component: () => import("@/views/taskModule/mineTask"),
  },
  {
    path: "taskModule/cc",
    name: "CC",
    meta: {
      title: "我的抄送",
    },
    component: () => import("@/views/taskModule/cc"),
  },
  {
    path: "taskModule/msgReminder",
    name: "MsgReminder",
    meta: {
      title: "消息提醒",
    },
    component: () => import("@/views/taskModule/msgReminder"),
  },
  {
    path: "sealManage",
    name: "SealManage",
    meta: {
      title: "用印管理",
    },
    component: () => import("@/views/sealManage"),
  },
  {
    path: "myRelated",
    name: "MyRelated",
    meta: {
      title: "与我相关",
    },
    component: () => import("@/views/myRelated"),
  },
];

// 系统管理
const SystemModule: Route[] = [
  {
    path: "systemModule/menuManagement",
    name: "MenuManagement",
    meta: {
      title: "菜单管理",
    },
    component: () => import("@/views/systemModule/menuManagement"),
  },
  {
    path: "systemModule/departmentManagement",
    name: "DepartmentManagement",
    meta: {
      title: "部门管理",
    },
    component: () => import("@/views/systemModule/departmentManagement"),
  },
  {
    path: "systemModule/accountManagement",
    name: "AccountManagement",
    meta: {
      title: "用户管理",
    },
    component: () => import("@/views/systemModule/accountManagement"),
  },
  {
    path: "systemModule/roleManagement",
    name: "RoleManagement",
    meta: {
      title: "角色管理",
    },
    component: () => import("@/views/systemModule/roleManagement"),
  },
  {
    path: "systemModule/postManagement",
    name: "PostManagement",
    meta: {
      title: "岗位管理",
    },
    component: () => import("@/views/systemModule/postManagement"),
  },
  {
    path: "systemModule/dictionaryManagement",
    name: "DictionaryManagement",
    meta: {
      title: "字典管理",
    },
    component: () => import("@/views/systemModule/dictionaryManagement"),
  },
  {
    path: "systemModule/dictionaryDataManagement",
    name: "DictionaryDataManagement",
    meta: {
      title: "字典数据管理",
    },
    component: () => import("@/views/systemModule/dictionaryDataManagement"),
  },
];

const DashboardRoutes: Route[] = [
  // {
  //   path: "",
  //   name: "Dashboard",
  //   meta: {
  //     title: "首页", // 标签页缺省标题
  //     isPin: true, // 是否固定
  //     isCache: false, // 是否启用 keep-alive 缓存
  //   },
  //   component: () => import("@/views/dashboard"),
  // },
  ...TaskModule,
  ...SystemModule,
];

const Routes: Route[] = [
  {
    path: "/dashboard",
    name: "_Dashboard",
    component: AsyncPageWrap(() => import("@/layouts/dashboard/Layout.vue")),
    children: DashboardRoutes.map((x) => {
      return {
        ...x,
        component: MainerWrap(x.component as AsyncComponentLoader, x.name),
      };
    }),
  },
  {
    path: "/login",
    name: "Login",
    component: AsyncPageWrap(() => import("@/views/login/View.vue")),
  },
  {
    path: "/auth",
    name: "Auth",
    component: AsyncPageWrap(() => import("@/views/auth")),
  },
  {
    path: "/authFS",
    name: "AuthFS",
    component: AsyncPageWrap(() => import("@/views/authFS")),
  },
  {
    path: "/",
    name: "Home",
    component: AsyncPageWrap(() => import("@/views/home")),
  },
  {
    path: "/blank",
    name: "Blank",
    component: AsyncPageWrap(() => import("@/views/blank")),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: AsyncPageWrap(() => import("@/views/notFound")),
  },
];

export { Routes, DashboardRoutes };
