import {
  AsyncComponentLoader,
  defineAsyncComponent,
  defineComponent,
  h,
} from "vue";
import { Mainer } from "./mainer";

const MainerWrap = (component: AsyncComponentLoader, name?: string) => {
  const children = defineAsyncComponent(component);

  return defineComponent({
    name,
    render() {
      return h(
        Mainer,
        {
          routeName: name,
        },
        {
          default: () => h(children),
        }
      );
    },
  });
};

export default MainerWrap;
